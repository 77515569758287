<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1"
        >

            <div class="pb-2 mt-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        />

                    </b-col>

                    <b-col
                            cols="12"
                            md="6"
                    >
                        <div class="d-flex align-items-center justify-content-end">

                            <b-button v-if="$can('edit', MODULE_PREFIX+'.tab_'+ PREFIX)"
                                      variant="primary"
                                      @click="tabItem = false; $bvModal.show(PREFIX + '-modal')"
                            >
                                <span class="text-nowrap">{{ $t('label_add_a_new_clause') }}</span>
                            </b-button>
                        </div>
                    </b-col>

                </b-row>

            </div>


            <div class="table-container-wrap">
                <b-table

                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(is_accepted)="data">

                        <!--<label>{{ $t('label_recommended') }}</label>-->
                        <b-form-checkbox
                                :checked="data.item.is_accepted == 1"
                                true-value="1"
                                false-value="0"
                                @change="changeAcception($event,data.item )"
                        >
                            {{ (data.item.is_accepted == 1)? $t('label_yes') : $t('label_no')}}
                        </b-form-checkbox>


                    </template>


                    <template #cell(content)="data">

                        <partial-text :text="data.item.content"></partial-text>

                    </template>
                    <template #cell(effective_from_at)="data">

                        {{formatDate(data.item.effective_from_at, 'DD.MM.YYYY', 'unix') + (data.item.effective_to_at? '
                        - ' + formatDate(data.item.effective_to_at, 'DD.MM.YYYY', 'unix'):'')}}

                    </template>


                    <template #cell(actions)="data">

                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item
                                    @click=" $bvModal.show(PREFIX + '-modal');tabItem = data.item">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>

                        </b-dropdown>
                    </template>

                </b-table>
            </div>
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

        <rodo-modal @item-added="refreshDataTable" @item-edited="refreshDataTable" :is-deal="isDeal" :module-item="moduleItem" :edited-item="tabItem"></rodo-modal>
    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton

    } from 'bootstrap-vue'


    import VueUploadComponent from 'vue-upload-component'

    import vSelect from 'vue-select'

    import partialText from '@/views/components/partialText'
    import rodoModal from './../modals/processRodoModal'

    import {
        AGREEMENT_PREFIX,
        DEAL_PREFIX,
        RODO_PREFIX as PREFIX
    } from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton,
            vSelect, VueUploadComponent, partialText, rodoModal
        },
        props: ['moduleItem', 'isDeal'],
        data() {
            return {
                PREFIX,
                DEAL_PREFIX,
                AGREEMENT_PREFIX,
                MODULE_PREFIX:'',

                tabItem: false,

                columnsDefs: [
                    // {label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden'},
                    {label: 'label_sonsensus', key: 'is_accepted'},
                    {label: 'label_name', key: 'name', sortable: false},
                    {label: 'label_clause', key: 'content', sortable: false},
                    {label: 'label_since_is_it_in_force', key: 'effective_from_at'},
                    {label: 'label_action', key: 'actions'}
                ],
                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },
                files: [],
                uploadedFies: []
            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
          moduleItem: {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.refreshDataTable();
              }
            }
          }
        },

        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get',  '/agreement_' + this.PREFIX, {

                    params: {

                        ['agreement_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            changeAcception($event, item) {

                this.async('post', '/agreement_' + this.PREFIX + '/accept_rodo', {
                    is_accepted: $event,
                    ['agreement_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                    rodo_id: item.id
                }, function (res) {
                   this.refreshDataTable();
                });
            },


        },

        created() {

            let self = this;

            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if ((obj.key == 'is_accepted' || obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX + '.tab_' + self.PREFIX)) {
                    return false;
                }
                return true;

            });
            this.refreshDataTable();

        },
    }
</script>
