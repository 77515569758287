<template>
  <vue-perfect-scrollbar
    :settings="perfectScrollbarSettings"
    class="hovered-list scroll-area"
    :tagname="tagname? tagname : 'div'"
  >
    <ul
      :style="{height:(items.length < 1? 'auto' : listHeight)}"
      class="hovered-list-container"
    >
      <template v-for="item, ind in items">
        <slot
          :item="item"
          :ind="ind"
          name="item"
        >
          Item
        </slot>
      </template>
      <li
        v-show="first_init || hasNextPage"
        ref="load"
        class="loader"
      >
        {{ $t('label_loading') }}...
      </li>
    </ul>

    <div
      v-if="!first_init"
      class="no-results"
      :class="{'show': items.length< 1}"
    >
      <div class="text-center my-2">
        {{ $t('label_no_items') }}
      </div>
    </div>
  </vue-perfect-scrollbar>

</template>

<script>

    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    // import vSelect from 'vue-select'

    export default {

        name: "InfiniteList",
        components:{
            VuePerfectScrollbar
        },

        props: ['url', 'queryParams', 'refreshList', 'tagname', 'count', 'height'],

        data() {
            return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 100,
            },
            first_init: true,
            total:0,
            items:[],
                listHeight: this.height? this.height: '400px',
            observer: null,
            limit: (this.count? this.count : 10),
            page:1,
            search: '',
            getting_data: false,
            disableWatchSearch:false,
            // defaultItemWasShown:false,
        }
        },


        computed: {
            hasNextPage () {
                return this.items.length < this.total;
            },
        },
watch:{
            refreshList: function(newVal){
                if(newVal) {
                    this.init();
                    this.$emit('update:refreshList', false);
                }
            }
},

        mounted () {

            this.observer = new IntersectionObserver(this.infiniteScroll, {threshold: 0});
            this.init();
            // this.observer = new IntersectionObserver(this.infiniteScroll);
        },
        beforeDestroy(){
            this.observer.disconnect();
        },
        methods: {

            async infiniteScroll ([{isIntersecting, target}]) {

                if (isIntersecting) {
                    const ul = target.offsetParent; // getting closest parent with position relative/absolute
                    const scrollTop = target.offsetParent.scrollTop;// (сколько пикселей отступил от топа скрол) прокручено за пределы родителя //Получаем количество прокрученных пикселей

                    await this.getData();
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                    this.observer.disconnect();
                    if (this.hasNextPage) {
                        this.observer.observe(this.$refs.load)
                    }
                }
            },
            getData(fresh){
                if(this.getting_data) { return; }
                if(fresh){
                    this.first_init = true;
                    this.page = 1;
                    this.total = 0;
                    this.items = [];
                }
                this.getting_data = true;
                let params = {
                    length: this.limit,
                    start: (this.page - 1) * this.limit,
                    search:this.search
                };
                if(this.queryParams){
                    params = Object.assign(params, this.queryParams);
                }

               return new Promise((resolve, reject) => {
                   this.async('get', this.url, {params: params}, function (resp) {
                       this.items = this.items.concat(resp.data.items);

                       this.page+=1;
                       this.total = parseInt(resp.data.total);

                       this.getting_data = false;
                       if(this.first_init) {
                           this.first_init = false;
                       }
                       this.$emit('data-fetched', {total:this.total});
                       return resolve(resp);

                   });
               });
            },

            // search: _.debounce((loading, search, vm) => {
            //     fetch(
            //         `https://api.github.com/search/repositories?q=${escape(search)}`
            //     ).then(res => {
            //         res.json().then(json => (vm.options = json.items));
            //         loading(false);
            //     });
            // }, 350)
            async init(){
                await this.getData('fresh');
                if (this.hasNextPage) {

                    await this.$nextTick();

                    this.observer.observe(this.$refs.load)
                }
            }
        }
    }
</script>


<!--<style lang="scss">-->
    <!--/*@import '@core/scss/base/bootstrap-extended/include';*/-->



<!--</style>-->
