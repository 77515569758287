<template>
  <!--static-->
  <b-modal
    :id="PREFIX + '-modal'"
    hide-footer
    @hide="resetForm()"
    @shown="initData()"
  >
    <template #modal-title>
      {{ action == 'editing'?$t('label_edit_document'):$t('label_add_document') }}
    </template>
    <template #default="{ hide }">


      <validation-observer
        #default="{handleSubmit, reset, invalid}"
        :ref="PREFIX + '_FORM'"
        tag="form"
        class="p-1"
      >
        <validation-provider
          #default="validationProps"
          :name="$t('label_document_name')"
          rules="required"
          slim
        >
          <b-form-group
            :label="$t('label_document_name')"
          >
            <b-form-input
              v-model="itemData.name"
              :state="getValidationState(validationProps)"
              :placeholder="$t('label_document_name')"
            />

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider

          #default="validationProps"
          :name="$t('label_document_type')"
          rules="required"
          slim
        >
          <b-form-group
            :label="$t('label_document_type')"
          >
            <v-select
              v-model="itemData.doc_type"
              class="flex-grow-1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="docTypes"
              :reduce="val => val.alias"
              label="label"
              :clearable="false"
              :placeholder="$t('label_document_type')"
            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.label) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                <span>{{ $t(option.label) }}</span>
              </template>
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>

            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


        <validation-provider

          #default="validationProps"
          :name="$t('label_description')"
          rules=""
          slim
        >
          <b-form-group
            :label="$t('label_description')"
          >
            <b-form-textarea
              v-model="itemData.description"
              :placeholder="$t('label_description')"
              :state="getValidationState(validationProps)"
            />

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <label
            for="share_to_role"
            class="w-100"
        >
          <b-form-checkbox
              v-model="selectedAll"
              inline
              name="event-filter-all"
              class="mb-1"
              :class="`custom-control-primary`"
              @change="selectAllRoles"
          >
            {{ $t('label_share_to_role') }}
          </b-form-checkbox>
        </label>
        <b-form-checkbox-group
          id="share_to_role"
          v-model="itemData.share_to_role"
          name="share_to_role"
          stacked
          :label="$t('label_share_to_role')"
        >
          <b-form-checkbox
            v-for="item in roleItems"
            :key="item.role"
            :disabled="item.disabled"
            name="event-filter"
            :value="getRole(item.role)"
            class="mb-1 col-sm-6"
            :class="`custom-control-primary`"
          >
            {{ $t('label_' + item.role) }}
          </b-form-checkbox>
        </b-form-checkbox-group>

        <validation-provider
          v-if="action == 'adding'"
          #default="validationProps"
          class="mt-2"
          :name="$t('label_to_ocr')"
          slim
        >
          <b-form-group
            label=""
            label-for="meeting_type_id"
          >

            <b-form-checkbox
              id="ad-ocr"
              v-model="itemData.ocr"
              value="1"
              unchecked-value="0"
            >
              <label for="ad-ocr">{{ $t('label_to_ocr') }}</label>
            </b-form-checkbox>
          </b-form-group>
        </validation-provider>


        <b-form-checkbox
            v-model="notifyUsers"
            name="event-filter"
            class="mb-1"
            :class="`custom-control-primary`"
        >
          {{ $t('label_notify_users') }}
        </b-form-checkbox>
        <validation-provider
            v-if="notifyUsers"
            #default="validationProps"
            :name="$t('label_recipients')"
            rules="required-if-empty:@written_recipients"
            slim
            :skip-if-empty="false"
            vid="chosen_recipients"
        >
          <b-form-group
              :label="$t('label_recipients')"
          >
            <v-select
                v-model="itemData.recipients"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userEmails"
                :reduce="val => val.id"
                :clearable="true"
                multiple
                :placeholder="$t('label_select')"
            >
              <template v-slot:option="option">
                <!--<span v-if="option.group_label != 'undefined'">{{option.group_label}}sdsd</span>-->
                <span  class="">{{ option.name + ' ('+$t('label_'+option.role)+')'}}</span>
              </template>
              <template v-slot:selected-option="option">
                <span class="">{{option.name}}</span>
              </template>
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>

            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>

          </b-form-group>
        </validation-provider>


        <validation-provider
          v-if="action == 'adding'"
          #default="validationProps"
          class="mt-2"
          :name="$t('label_document')"
          slim
          :rules="editedItem? '' : 'required'"
        >
          <file-input
            v-model="document"
            class="file--uploader size--big drop-block "
            :max-files-count="30"
            :multiple="true"
            :allowed-extensions="false"
            :max-file-size="150"
            :url="''"
          >
            <template #uploadBody>
              <feather-icon
                class="mr-1"
                data-toggle
                icon="DownloadCloudIcon"
                size="24"
              />
              <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
              <span>{{ $t('label_add_file') }}</span>
            </template>

            <template #errorBug="fileErrors">

              <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{ validationProps.failedRules.length > 0 ? validationProps.errors[0] : fileErrors.errors[0] }}</span>

            </template>
          </file-input>
          <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
            {{ validationProps.errors[0] }}
          </b-form-invalid-feedback>

        </validation-provider>

        <b-progress
          v-if="percentsHttpRequestDone"
          :value="percentsHttpRequestDone"
          max="100"
          show-progress
          animated
        />

        <div class="d-flex justify-content-end mt-2">
          <button
            type="reset"
            class=" btn btn-default"
            @click.prevent="$bvModal.hide(PREFIX + '-modal')"
          >
            {{ $t('label_close') }}
          </button>

          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
            @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
          >{{ (action ==
            'editing')?$t('label_save'):$t('label_submit') }}
          </button>
        </div>

      </validation-observer>

    </template>

  </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea, BFormCheckboxGroup,
        BFormGroup, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormInput, BProgress
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import fileInput from '@/views/components/fileUploadInput'

    import {serialize} from 'object-to-formdata';
    import {
        DOCUMENT_PREFIX as PREFIX, AGREEMENT_PREFIX, DEAL_PREFIX, docTypes
    } from "../moduleHelper"


    export default {
        components: {
            BFormCheckboxGroup,
            BFormGroup,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BFormInput,
            BProgress,
            ValidationProvider,
            ValidationObserver,
            fileInput,
            vSelect
        },
        props: ['moduleItem', 'editedItem', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX:'',
                PREFIX,
                docTypes,
                userData: JSON.parse(localStorage.getItem('userData')),
                action: 'adding',
                blankItemData: {
                    recipients: [],
                    share_to_role: [],
                    name: '',
                    ocr: 0,
                    doc_type:'',
                    description:''
                },
                document: [],
                itemData: false,
                roleItems: [],
                userEmails: [],
                notifyUsers: false,
              selectedAll: false,
            }
        },
      computed: {
        defaultRoles() {
          return this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
        disabledRoles() {
          return this.roleItems.map(role => (!role.disabled ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
      },
      watch:{
          'itemData.share_to_role': {
              deep: true,
              handler(newValue) {
                  if ((this.disabledRoles.length + this.defaultRoles.length) === newValue?.length) this.selectedAll = true
                  else this.selectedAll = false
              },
          },
        moduleItem: function(newVal){
          if(newVal){
            this.async('get', 'agreement/api_getAgreementExecutors/' + this.moduleItem.agreement_id, {}, function(res){
              this.userEmails = res.data;
            });
          }
        }
      },
      created(){
          this.async('get', 'agreement/api_getAgreementExecutors/' + this.moduleItem.agreement_id, {}, function(res){
            this.userEmails = res.data;
          });
          this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
      },
      async mounted() {
        await this.getPageRoles()
      },
        methods: {
            selectAllRoles(value) {
                if (value) this.itemData.share_to_role = [...this.disabledRoles, ...this.defaultRoles]
                else this.itemData.share_to_role = [...this.defaultRoles]
            },
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';

                    let item = { ...this.blankItemData};
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.name =  this.editedItem.display_name;
                    item.doc_type =  this.editedItem.type;
                    item.recipients = this.editedItem?.recipients || []
                    item.share_to_role = (typeof item.share_to_role === 'string' ? item.share_to_role?.split(',').filter(Boolean) : item.share_to_role) ?? []
                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = { ...this.blankItemData}
                    this.itemData.share_to_role = this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
                }
            },
            resetForm() {
                this.itemData = { ...this.blankItemData};
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
          async getPageRoles(){
            this.roleItems = this.getSystemRoles(this.userData?.role)
          },
            onSubmit() {

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);
                formData.append('item_id', this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id);
                formData.append('tab', this.isDeal? 'deal' : 'agreement');
                if (this.document.length > 0) {
                    // formData.append('document[]', this.document[0], this.document[0].name);
                    for (let i = 0; i < this.document.length; i++) {
                      formData.append('document[]', this.document[i], this.document[i].name);
                    }
                }

                if (this.notifyUsers) {
                  if (this.itemData.recipients.length > 0) {
                    this.itemData.recipients.forEach(recipient => {
                      formData.append('notify_users[]', recipient)
                    })
                  }
                  formData.append('email_content', this.itemData.description)
                  formData.append('email_title', this.itemData.name)
                }

                if (this.action == 'adding') {
                    this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's', formData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false, {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                } else {
                    formData.append('id', this.editedItem.id);
                    this.async('put', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/' + this.itemData.id, formData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}});
                    if (this.itemData.share_to_role?.length) {
                      this.async('put', '/agreement_documents/share/' + this.itemData.id, { share_to_role: this.itemData.share_to_role }, function(resp){ // this.itemData.contact_id
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                      }, false, null, true);
                    }
                }

            }
        },

    }
</script>
