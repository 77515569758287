<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'agreements' }">{{$t('label_agreements')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body>
            <b-card-body class="tab-tools">
                <div class="text-uppercase mr-1 mb-1"><strong>
                  {{$t('label_agreement')+' '+ (moduleItem.first_name? moduleItem.first_name.ucFirst() + ' ' + moduleItem.last_name.ucFirst() : '')}}
                  <span v-if="moduleItem.firm_name"> ({{ moduleItem.firm_name }})</span>
                </strong>
                </div>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_details')" class="mr-1" :to="{name:'agreement_edit', params:{id:moduleItem.id}}">
                    <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_tasks')" class="mr-1" @click="editedTask = false;$bvModal.show(TASK_PREFIX + '-modal')">
                    <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_add_task')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_notes')" class="mr-1" @click="editedNote = false;$bvModal.show(NOTE_PREFIX + '-modal')">
                    <feather-icon
                            icon="BookmarkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_add_note')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_sms')" class="" @click="editedSms = false;$bvModal.show(SMS_PREFIX + '-modal')">
                    <feather-icon
                            icon="BookmarkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_send_sms')}}</span>
                </b-button>

            </b-card-body>
        </b-card>

        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1" >
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_details')"
                           @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"
                            lazy
                    >

                        <tab-details @annex-added="fetchModuleItem($router.currentRoute.params.id)" :edited-item="moduleItem" :is-deal="is_deal"></tab-details>

                    </b-tab>


                    <b-tab
                            :ref="NOTE_PREFIX + '_TAB'"
                            v-if="$can('view', MODULE_PREFIX+'.tab_notes')"
                            @click="curPageLabel = $t('label_agreements_tab_notes')"
                            :title="$t('label_agreements_tab_notes')"
                            lazy
                    >
                        <tab-notes
                                @edit-item="editedNote = $event"
                                :ref="NOTE_PREFIX + '_CONTENT_TAB'"
                                :module-item="moduleItem"
                                :is-deal="is_deal"
                        ></tab-notes>

                    </b-tab>

                    <b-tab :ref="TASK_PREFIX + '_TAB'"
                           :is-deal="is_deal"
                           :module-item="moduleItem"
                            v-if="$can('view', MODULE_PREFIX+'.tab_'+TASK_PREFIX+'s')"
                            @click="curPageLabel = $t('label_tasks')"
                           :title="$t('label_tasks')"
                            lazy
                    >

                        <tab-tasks
                                :module-item="moduleItem"
                                :ref="TASK_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedTask = $event;"
                                :is-deal="is_deal"
                        ></tab-tasks>

                    </b-tab>

                    <b-tab
                        class=""
                        v-if="$can('view', MODULE_PREFIX+'.tab_documents')"
                        @click="curPageLabel = $t('label_documents')"
                        :title="$t('label_documents')"
                        lazy
                >

                    <tab-documents :module-item="moduleItem" :is-deal="is_deal"></tab-documents>

                </b-tab>

                    <b-tab ref="agreement_tab"
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_payments')"
                            @click="curPageLabel = $t('label_payments');"
                            :title="$t('label_payments')"
                            lazy
                    >

                        <tab-payments  :module-item="moduleItem" :is-deal="is_deal"></tab-payments>

                    </b-tab>
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_rodo')"
                            @click="curPageLabel = $t('label_rodo')"
                            :title="$t('label_rodo')"
                            lazy
                    >

                        <tab-rodo :module-item="moduleItem" :is-deal="is_deal"></tab-rodo>

                    </b-tab>

                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_history')"
                            @click="curPageLabel = $t('label_history')"
                            :title="$t('label_history')"
                            lazy
                    >

                        <tab-history :module-item="moduleItem" :is-deal="is_deal"></tab-history>

                    </b-tab>

                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_sms')"
                            @click="curPageLabel = $t('label_sms')"
                            :title="$t('label_sms')"
                            lazy
                    >

                        <tab-sms
                                module-type="agreement"
                                :module-item="moduleItem"
                                :ref="SMS_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedSms = $event;"
                        ></tab-sms>

                    </b-tab>
                </b-tabs>
            </b-card-body>
        </b-card>

        <!--modals-->
        <process-note-modal
                :is-deal="is_deal"
                :module-item="moduleItem"
                :edited-item="editedNote"
                @item-edited="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-note-modal>
        <process-task-modal
                :is-deal="is_deal"
                :module-item="moduleItem"
                :edited-item="editedTask"
                @item-edited="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-task-modal>
        <process-sms-modal
            module-type="agreement"
            :module-item="moduleItem"
            :edited-item="editedSms"
            @item-edited="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
            @item-added="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-sms-modal>

    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import processTaskModal from './modals/processTaskModal'
    import processNoteModal from './modals/processNoteModal'
    import processSmsModal from './modals/processSmsModal'

    import tabDetails from './tabs/tabDetails'
    import tabNotes from './tabs/tabNotes'
    import tabTasks from './tabs/tabTasks'
    import tabRodo from './tabs/tabRodo'
    import tabDocuments from './tabs/tabDocuments'
    import tabPayments from './tabs/tabPayments'
    import tabHistory from './tabs/tabHistory'
    import tabSms from './tabs/tabSms'

    import {AGREEMENT_PREFIX as MODULE_PREFIX, TASK_PREFIX, RODO_PREFIX, NOTE_PREFIX, SMS_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton,

            processTaskModal,
            processNoteModal,
            processSmsModal,

            tabDetails,
            tabTasks,
            tabSms,
            tabNotes,
            tabRodo,
            tabDocuments,
            tabPayments,
            tabHistory

        },
        data() {
            return {

                TASK_PREFIX,
                SMS_PREFIX,
                NOTE_PREFIX,
                RODO_PREFIX,
                MODULE_PREFIX,

                is_deal: false,

                editedTask:false,
                editedNote:false,
                editedSms:false,
                curPageLabel: 'label_informations',
                moduleItem: false,

            }
        },
        watch: {
          '$route.params': {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.fetchModuleItem(newValue.id)
              }
            }
          }
        },

        created() {
           let module_item_id = this.$router.currentRoute.params.id;

            this.fetchModuleItem(module_item_id);

        },

        methods: {

            fetchModuleItem(id){
                let self = this;
                this.async('get', '/'+this.MODULE_PREFIX+'/' + id, {}, function (resp) {
                    this.moduleItem = resp.data;

                    let alias_route_param = this.$router.currentRoute.params.alias;

                    if(alias_route_param){
                        this.$nextTick(function(){self.$refs[alias_route_param+'_TAB']?self.$refs[alias_route_param+'_TAB'].activate() : false;});
                    }
                });

            },

            resolveActiveTab(){
                return false;
            }

        },


    }
</script>

