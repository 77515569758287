<template>
  <b-row>

    <b-col
      cols="12"
      md="12"
      class=" mb-1"
    >

      <div class="pb-2 mt-1 table-top-toolbar">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="tableData.perPage"
              :searchable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tableData.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>

          </b-col>



        </b-row>

      </div>

      <div class="table-container-wrap">
        <b-table

          :ref="PREFIX+'_TABLE'"
          class="position-relative transited-table"
          :items="tableItems"
          responsive
          no-border-collapse
          :busy="isBusy"
          :fields="columnsDefs"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
        >

          <template #head()="data">
            <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
          </template>

          <template #cell(counter)="data">

            {{ data.index +1 }}

          </template>


        </b-table>
      </div>
      <!--pagination-->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="tableData.currentPage"
              :total-rows="tableTotal"
              :per-page="tableData.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-col>

  </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton

    } from 'bootstrap-vue'

    import VueUploadComponent from 'vue-upload-component'

    import vSelect from 'vue-select'

    import  partialText from '@/views/components/partialText'

    import { AGREEMENT_PREFIX, DEAL_PREFIX, HISTORY_PREFIX as PREFIX} from "../moduleHelper"

    export default {
        components: {

            BTable,
BDropdownItem,
BDropdown,
BPagination,
BFormCheckbox,
BButton,
            vSelect,
VueUploadComponent,
partialText
        },
        props: ['moduleItem', 'isDeal'],
        data() {
            return {
                PREFIX,
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',

                tabItem:false,

                columnsDefs: [
                    {
label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden'
},
                    {label: 'label_sl_no', key: 'counter'},
                    {
label: 'label_adding_date',
key: 'created_at',
sortable: false,
formatter: function (v, k, item) {
                            return formatDate(v, 'HH:mm DD.MM.YYYY', 'unix');
                        }
},
                    {label: 'label_user', key: 'user_name', sortable: false},
                    {label: 'label_type_of_action', key: 'action_type_name', sortable: false},
                    {label: 'label_field', key: 'field_trans', sortable: false},
                    {label: 'label_changes', key: 'changing_info', sortable: false}
                ],
         isBusy:false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },
                files:[],
                uploadedFies:[]
            }
        },
        computed: {
            dataMeta: (context) => ({
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }),
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
          moduleItem: {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.refreshDataTable();
              }
            }
          }
        },

        created() {

            let self = this;
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if ((obj.key == 'is_accepted' || obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX+'.tab_' + self.PREFIX)) {
                    return false;
                }
                return true;

            });
            this.refreshDataTable();

        },
        methods: {
            refreshDataTable: function () {
           this.isBusy = true;
                this.async('get',  '/agreement_' + this.PREFIX, {

                    params: {

                        'agreement_id': this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                        // [this.MODULE_PREFIX + '_id']: this.moduleItem.id,

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {
                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                this.isBusy = false;
                });
            },
        },
    }
</script>
