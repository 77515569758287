<template>
    <div class="mb-2 mt-2">

        <infinity-list
                :url="listUrl"
                :refresh-list.sync="refreshList"
                :queryParams="queryParams"
                height="2000px"
        >
            <template #item="{item, ind}">
                <b-media tag="li" no-body>

                    <b-media-body>
                        <div class="mail-details">
                            <div class="mail-items">
                                <h5 class="mb-25 d-flex align-items-center">
                                    <span>
                                        <strong class="text-primary">{{item.user.name}}</strong>

                                        {{$t('label_posted_on')}} - <span class="text-info">
                                        {{formatDate(item.created_at, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss')}}</span>&nbsp;

                                        <span class="text-success">{{item.title}} </span>
                                    </span>

                                    <b-button @click="resendMail(item.id)" variant="outline-primary" size="sm" pill class="ml-1"
                                              v-if="item.is_sended == 0 && item.canResend"
                                        :title="$t('question_resend')">
                                        <feather-icon icon="RepeatIcon"></feather-icon>
                                    </b-button>

                                </h5>

                            </div>

                        </div>

                        <div class="mail-message" v-html="$options.filters.nlToBr(item.content)"></div>

                        <div v-if="item.documents && item.documents.length > 0">
                            <div class="text-primary mb-1">{{$t('label_documents')}}</div>
                            <div v-for="doc in item.documents">
                                <a class="text-success" :href="$base_url + '/deal_emails/'">{{doc.display_name}}</a>
                            </div>
                            <!--<a  v-for="doc, ind in item.documents" class="text-success mr-1" :href="$base_url + '/deal_emails/'">{{doc.display_name + ((ind+1) ==item.documents.length? '' :',')}}</a>-->
                        </div>
                        <br>
                        <div v-if="item.phones || item.recipients">
                            <div class="text-primary mb-1">{{$t('label_recipients')}}</div>
                            <div>
                                <feather-icon class="mr-1" icon="PhoneIcon"></feather-icon>
                                <span v-if="item.phones" class="text-secondary">{{item.phones + (item.recipients.length > 0? ', ' : '')}}</span>
                                <template v-if="item.recipients.length > 0">
                                    <span v-for="res,k in item.recipients" class="text-secondary">{{(res.name? res.name : $t('label_client'))  + ' (' + res.phone + ')' + ((k+1 != item.recipients.length)? ', ' : '')}}</span>
                                </template>
                            </div>


                        </div>

                    </b-media-body>
                </b-media>
            </template>
        </infinity-list>

    </div>
</template>

<script>
    import {
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BButton

    } from 'bootstrap-vue'
    import infinityList from '@/views/components/infinityList'
    import {SMS_PREFIX as PREFIX} from './../moduleHelper'

    export default {

        components: {
            BMedia,
            BMediaAside,
            BMediaBody,
            BAvatar,
            BButton,
            infinityList
        },

        props: ['moduleItem', 'moduleType', 'editedItem'],

        data() {
            return {
                PREFIX,
                refreshList: false,
            }
        },
        computed: {
            listUrl: function(){
                if(this.moduleType == 'contact') {
                    return 'contact_sms';
                } else {
                    return 'agreement_sms';
                }
            },
            queryParams: function(){
                if(this.moduleType == 'contact') {
                    return {contact_id : this.moduleItem.id};
                } else if(this.moduleType == 'deal'){
                    return {agreement_id : this.moduleItem.agreement_id};
                } else if(this.moduleType == 'agreement') {
                    return {agreement_id : this.moduleItem.id};
                }
            }
        },
      watch:{
        moduleItem: {
          deep: true,
          handler(newValue, oldValue) {
            if (newValue?.id !== oldValue?.id) {
              this.refreshDataTable();
            }
          }
        }
      },
        methods: {

            refreshDataTable() {
                this.refreshList = true;
            },

            resendMail(mail_id){
                this.async('get', '/'+this.moduleType+'_sms/resend/' +mail_id, {}, function (resp) {
                    // this.$router.push({name: 'mails_' + this.itemData.mail_type});
                }, false);
            }

        },
        created(){
            this.$root.$refs[this.PREFIX + '_CONTENT_TAB'] = this;
        },
        beforeDestroy(){
            delete this.$root.$refs[this.PREFIX + '_CONTENT_TAB'];
        }

    }
</script>
