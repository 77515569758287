import Sortable, { MultiDrag, Swap } from 'sortablejs';
// window.Sortable = Sortable;
// https://github.com/SortableJS/Sortable
const createSortable = (el, options, vnode) => {

    return Sortable.create(el, {
        ...options
    });
};

export const trSortable = {
    name: 'tr-sortable',
    bind(el, binding, vnode) {
        const table = el;
        table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode);
    }
};
// import {trSortable} from '@src/utils/directives'directives(trSortable) v-tr-sortable