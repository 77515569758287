<template>
  <b-row>
    <transition name="fade">
      <div class="position-fixed" style="right: 30px; bottom: 10px; z-index: 99999999" v-if="checkedIds.length > 0">
        <!-- Action -->
        <b-button
            class="mr-25"
            size="sm"
            variant="danger"
            @click="deleteSelectedItems()"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">{{ $t('label_delete_selected') }} ({{ checkedIds.length }})</span>
        </b-button>
        <b-button
            size="sm"
            variant="primary"
            @click="downloadSelectedItems()"
        >
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">{{ $t('label_download_chosen') }} ({{ checkedIds.length }})</span>
        </b-button>
      </div>
    </transition>

    <b-col
      cols="12"
      md="12"
      class="mb-1 3"
    >
      <div class="pb-2 mt-1 table-top-toolbar">
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="12"
            class="d-flex align-items-center justify-content-start mb-md-0 "
          >
            <v-select
              v-model="tableData.perPage"
              :searchable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tableData.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mr-1 mb-1"
            >
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>
            <b-form-radio
              v-for="(docType, ind) in docTypes"
              :key="ind"
              v-model="filterData.doc_type"
              class="mr-1 mb-1"
              button
              size="md"
              button-variant="outline-primary"
              :value="docType.alias"
            >
              <span style="white-space: nowrap;">{{ $t(docType.label) }}</span>
            </b-form-radio>

          </b-col>

          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 ml-auto"
          >
            <b-form-input
              v-model="tableData.searchQuery"
              type="search"
              debounce="500"
              class="d-inline-block mr-1 ml-auto mb-1"
              :placeholder="$t('label_search')+'...'"
            />
            <b-button
              v-if="$can('edit', MODULE_PREFIX+'.tab_'+PREFIX+'s')"
              class="mb-1"
              variant="primary"
              @click="tabItem = false; $bvModal.show(PREFIX + '-modal')"
            >
              <span class="text-nowrap">{{ $t('label_add_new') }}</span>
            </b-button>
          </b-col>


        </b-row>

      </div>

      <div class="table-container-wrap">
        <b-table
          :ref="PREFIX+'_TABLE'"
          v-tr-sortable
          class="position-relative transited-table"
          :items="tableItems"
          responsive
          no-border-collapse
          :busy="isBusy"
          :fields="columnsDefs"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
          :tbody-tr-class="resolveRowClassVariant"
        >

          <template #head()="data">
            <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
          </template>

          <template #head(checkboxes)="data">
<!--            <b-dropdown-->
<!--              v-if="checkedIds.length > 0"-->
<!--              class="selected-items-dropdown"-->
<!--              variant="link"-->
<!--              no-caret-->
<!--              :right="$store.state.appConfig.isRTL"-->
<!--            >-->
<!--              <template #button-content>-->
<!--                <feather-icon-->
<!--                  icon="MoreVerticalIcon"-->
<!--                  size="16"-->
<!--                  class="align-middle text-body"-->
<!--                />-->
<!--              </template>-->

<!--              <b-dropdown-item-->
<!--                @click="deleteSelectedItems()"-->
<!--              >-->
<!--                <feather-icon icon="TrashIcon" />-->
<!--                <span class="align-middle ml-50">{{ $t('label_delete_selected') }}</span>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item-->
<!--                @click="downloadSelectedItems()"-->
<!--              >-->
<!--                <feather-icon icon="DownloadIcon" />-->
<!--                <span class="align-middle ml-50">{{ $t('label_download_chosen') }}</span>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--            <span-->
<!--              v-else-->
<!--              class="text-secondary"-->
<!--            >{{ $t(data.label).toUpperCase() }}</span>-->
            <div>
              <b-form-checkbox
                  v-model="checkAll"
                  @change="toggleChecked"
              />
            </div>
          </template>

          <template #cell(checkboxes)="data">

            <!--<label>{{ $t('label_recommended') }}</label>-->
            <b-form-checkbox
              v-model="checkedIds"
              :value="data.item.id"
            />

          </template>


          <template #cell(file_name)="data">
            <a
              v-b-tooltip.hover.top="getFileName(data.item)"
              class="preview-firstly"
              href="#"
              @click="previewFile($event, 'documents/download/', data.item)"
            >
              <feather-icon
                size="18"
                icon="FileTextIcon"
              />
            </a>
          </template>

          <template #cell(ocr_file_doc)="data">

            <template v-if="data.item.ocr_files && data.item.ocr_files.length > 0">
              <a
                v-for=" file in data.item.ocr_files"
                v-b-tooltip.hover.top="getFileName(file)"
                class="preview-firstly"
                href="#"
                @click="previewFile($event, 'documents/download/', file)"
              >
                <feather-icon
                  v-if="file.file_name.split('.').pop() == 'txt'"
                  size="18"
                  icon="FileTextIcon"
                />
                <feather-icon
                  v-else
                  size="18"
                  icon="FileIcon"
                />

              </a>

            </template>

          </template>

          <template #cell(ocr)="data">
            {{ data.item.ocr == 1 ? $t('label_yes') : $t('label_no') }}
          </template>

          <template #cell(description)="data">
            <div
              v-b-tooltip.hover.top="data.item.description"
              class="one-line-limit"
            >
              {{ data.item.description }}
            </div>
          </template>

          <template #cell(share_to_role)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              <feather-icon
                size="14"
                :icon="row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              />
            </b-button>
          </template>

          <template #row-details="row">
            <b-form-checkbox-group
              id="share_to_role"
              v-model="row.item.share_to_role"
              name="share_to_role"
              stacked
              :label="$t('label_share_to_role')"
              @input="updateShareToRoles(row.item.share_to_role, row.item)"
            >
              <b-form-checkbox
                v-for="item in roleItems"
                :key="item.role+row.item.id"
                :disabled="$can('edit', MODULE_PREFIX+'.tab_'+PREFIX+'s') && item.disabled"
                name="event-filter"
                :value="getRole(item.role)"
                class="mb-50 mr-2 pr-50"
                :class="`custom-control-primary`"
              >
                {{ $t('label_' + item.role) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>

          <template #cell(actions)="data">

            <b-dropdown
              :disabled="!($can('edit', MODULE_PREFIX+'.tab_'+PREFIX+'s') && ['admin', 'consultant'].includes(userData.role))"
              dropleft
              boundary="window"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
              @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>


              <b-dropdown-item
                @click=" $bvModal.show(PREFIX + '-modal');tabItem = data.item"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="['admin'].includes(userData.role)"
                @click="$emit('delete'+PREFIX.ucFirst(), data.item.id);deleteItem(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>
      <!--pagination-->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="tableData.currentPage"
              :total-rows="tableTotal"
              :per-page="tableData.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-col>

    <!--MODAL-->
    <document-modal
      :module-item="moduleItem"
      :edited-item="tabItem"
      :is-deal="isDeal"
      @item-added="refreshDataTable()"
      @item-edited="refreshDataTable()"
    />

  </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox,  VBTooltip,
        BButton, BAvatar, BTooltip, BFormInput, BFormRadio,
      BFormCheckboxGroup,
    } from 'bootstrap-vue'

    import VueUploadComponent from 'vue-upload-component'

    import vSelect from 'vue-select'
    import fileInput from '@/views/components/fileUploadInput'
    import partialText from '@/views/components/partialText'
    import {trSortable} from '@src/utils/directives'
    import documentModal from "../modals/processDocumentModal"
    import {
        AGREEMENT_PREFIX,
        DEAL_PREFIX,
        DOCUMENT_PREFIX as PREFIX,
        docTypes
    } from "../moduleHelper"

    export default {
        components: {
          BFormCheckboxGroup,
            BTable,
BDropdownItem,
BDropdown,
BPagination,
BFormCheckbox,
BButton,
BAvatar,
BTooltip,
BFormInput,
            vSelect,
VueUploadComponent,
BFormRadio,
fileInput,
partialText,
documentModal
        },
        directives: {
            trSortable,
            'b-tooltip': VBTooltip,
        },
        props: ['moduleItem', 'isDeal'],

        data() {
            return {
              userData: JSON.parse(localStorage.getItem('userData')),
              AGREEMENT_PREFIX,
                DEAL_PREFIX,
                PREFIX,
                MODULE_PREFIX: '',
                docTypes,

                tabItem: false,

                columnsDefs: [
                    {
 label: 'label_#', key: 'checkboxes', sortable: false, thClass: 'table-action-wrap'
},
                    {
 label: 'label_date_of_adding',
key: 'created_at',
formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                      }
                    },
                    {label: 'label_description', key: 'description', sortable: false },
                    {label: 'label_file_name', key: 'display_name', sortable: true},
                    {label: 'label_original_file', key: 'file_name', sortable: false},
                    {label: 'label_shared_for', key: 'share_to_role'},
                    {label: 'label_file_ocr', key: 'ocr_file_doc', sortable: false},
                    {label: 'label_to_ocr', key: 'ocr'},
                    {label: 'label_action', key: 'actions'}
                ],
                checkedIds: [],
                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },

                filterData: {
                    doc_type: 'default'
                },
                files: [],
                uploadedFies: [],
                roleItems: [],
                checkAll: false,
            }
        },
        computed: {
            dataMeta: (context) => ({
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }),
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.checkedIds = [];
                    this.refreshDataTable();
                },
                deep: true
            },
          checkedIds: {
              deep: true,
              handler(newVal, oldVal) {
                if (newVal.length < oldVal.length) {
                  this.checkAll = false
                }
              },
          },
        },

        created() {
          this.getPageRoles()
            let self = this;

            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if ((obj.key == 'is_accepted' || obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX + '.tab_' + self.PREFIX + 's')) {
                    return false;
                }
                return true
            });
            this.refreshDataTable();

        },
        methods: {
          toggleChecked(value) {
            if (value) this.checkedIds = this.tableItems.map(item => item.id)
            else this.checkedIds = []
          },
          updateShareToRoles(roles, item) {
            this.async('put', '/agreement_documents/share/' + item.id, { share_to_role: roles }, function(resp){ // this.itemData.contact_id
              // this.$bvModal.hide(this.PREFIX + '-modal');
            });
          },
            async getPageRoles(){
              this.roleItems = this.getSystemRoles(this.userData?.role)
            },
            refreshDataTable: function () {
                this.isBusy = true;

                this.async('get',  '/agreement_' + this.PREFIX + 's', {

                    params: {
                        agreement_id: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        extra_search: this.filterData.doc_type,

                    }
                }, function (resp) {
                    this.tableItems = resp.data.items.map(item => ({ ...item, share_to_role: item.share_to_role?.split(',').filter(Boolean) ?? [] }));
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });

                })
            },
            deleteSelectedItems() {
                this.confirmDeleting((result) => {

                    this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/remove_tab_documents', {
                        selected_ids: this.checkedIds
                    }, function (resp) {
                        this.checkedIds = [];
                        this.refreshDataTable();
                    });
                })
            },
            downloadSelectedItems() {
                let queryString = {ids:this.checkedIds}.objectToQueryString();
                let url = this.$base_url + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/download' + queryString;
                window.open(url);
            },

            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (this.checkedIds.includes(item.id)) return 'row-success';
            },
            getFileName(file){
                return file.display_name + '.' + file.file_name.split('.').pop();
            }


        },
    }
</script>
