<template>
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{action == 'editing'?$t('label_send_sms'):$t('label_send_sms')}}
        </template>
        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >

                <validation-provider
                                     class="mt-2"
                                     #default="validationProps"
                                     :name="$t('label_sms_patterns')"
                                     :rules="itemData.content? '' : 'required'"
                                     slim
                >
                    <b-form-group
                            :label="$t('label_sms_patterns')"
                    >
                        <infinity-scroll
                                selected-prop="id"
                                :multiple="false"
                                url="/sms_patterns"
                                :placeholder="$t('label_select')"
                                v-model="itemData.sms_pattern_id"
                                @option:selected="chosenPattern = $event; itemData.content = $event.pattern_content"
                        >
                            <!--:default-selection="chosenRelDocs"-->
                            <template #label="{item}">{{ item.pattern_name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_content')"
                        :rules="itemData.sms_pattern_id? '' : 'required'"
                        slim

                >
                    <b-form-group
                            :label="$t('label_content')"

                    >
                        <b-form-textarea
                                rows="5"

                                :state="getValidationState(validationProps)"
                                v-model="itemData.content"
                                :placeholder="$t('label_content')"
                        ></b-form-textarea>

                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_send_sms_to_client')"
                        slim

                >
                    <b-form-group
                            label=""
                    >

                        <b-form-checkbox
                                id="send_to_contact"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.send_to_contact"
                        >
                            <label for="send_to_contact">{{ $t('label_send_sms_to_client') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_recipients')"
                        :rules="itemData.send_to_contact? '' : 'required-if-empty:@written_recipients'"
                        slim
                        :skip-if-empty="itemData.send_to_contact? true: false"
                        vid="chosen_recipients"
                >
                    <b-form-group
                            :label="$t('label_recipients')"
                    >
                        <v-select
                                v-model="itemData.recipients"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="userPhones"
                                :reduce="val => val.id"
                                :clearable="true"
                                multiple
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <span  class="">{{ option.name + ' (' + (option.phone?option.phone:'no phone') + ')' }}</span>
                                <!--<span  class="">{{ option.name + ' ('+$t('label_'+option.role)+')'}}</span>-->
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{ option.name + ' (' + (option.phone?option.phone:'no phone') + ')' }}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_recipients')"
                        :rules="itemData.send_to_contact? '' : 'required-if-empty:@chosen_recipients'"
                        :skip-if-empty="itemData.send_to_contact? true : false"
                        slim
                        vid="written_recipients"
                >
                    <b-form-group
                            :label="$t('label_recipients')"
                    >
                        <b-form-input
                                :placeholder="$t('label_recipients')"
                                v-model="itemData.phones"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>



                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(modalRef? modalRef : PREFIX + '-modal')">
                        {{$t('label_cancel')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary">{{(action == 'editing')?$t('label_submit'):$t('label_submit')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {requiredAtLeastOne} from '@validations'

    import {
        BFormTextarea,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BBadge,
        BProgress
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  fileInput from '@/views/components/fileUploadInput'

    import infinityScroll from '@/views/components/infinityScroll'
    import {serialize} from 'object-to-formdata';

    import {SMS_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {
            // quillEditor,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BBadge,
            BProgress,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            fileInput,
            infinityScroll

        },
        props:['moduleType', 'moduleItem','editedItem', 'showAll'],
        data() {
            return {

                MODULE_PREFIX: this.moduleType,
                PREFIX,

                snowOption: {
                    theme: 'snow',
                },

                action: 'adding',

                blankItemData: {
                    id: 0,
                    content: '',
                    recipients:[],
                    phones:'',
                    sms_pattern_id: null,
                    send_to_contact: 0

                },

                itemData: {},
                userPhones:[],
                chosenPattern: false
            }
        },
        watch:{
            moduleItem: function(newVal){
                if(newVal){
                    let url;
                    if(this.moduleType == 'agreement') {
                        url = 'agreement/api_getAgreementExecutors/' + + newVal.id;
                    } else if(this.moduleType == 'contact'){
                        url = 'contact/getRelatedUsers/' + + newVal.id;
                    } else if(this.moduleType == 'deal'){
                        url = 'agreement/api_getAgreementExecutors/' + + newVal.agreement_id;
                    }
                    this.async('get',  url , {}, function(res){
                        if(this.moduleType == 'contact') {
                            let users = [];
                            if(res.data.consultants){
                                users = users.concat(res.data.consultants);
                            }
                            if(res.data.agents) {
                                users = users.concat(res.data.agents);
                            }
                            // users = users.concat({id: 'client_' + newVal.id, name: this.$t('label_client') + ' ' + newVal.first_name + ' ' + newVal.last_name, phone: newVal.phone});
                            this.userPhones = users;
                        } else {
                            this.userPhones = res.data;
                        }
                    });
                }
            }
        },
        methods: {
            initData() {
                if (this.editedItem) {

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }

            },
            resetForm() {

                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData = new FormData();
                if(this.moduleType == 'agreement') {
                    formData.append('agreement_id', this.moduleItem.id);
                } else if(this.moduleType == 'contact'){
                    formData.append('contact_id', this.moduleItem.id);
                } else if(this.moduleType == 'deal'){
                    formData.append('agreement_id', this.moduleItem.agreement_id);
                }

                formData = serialize(this.itemData, {}, formData);

                if (this.action == 'adding') {
                   let url =  (this.moduleType != 'contact'? 'agreement' : this.MODULE_PREFIX )+ '_' + this.PREFIX;
                    this.async('post', url , formData, function (resp) { //this.itemData.contact_id
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }

            },


        },

    }
</script>

