<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1 mt-1"
        >

            <div class="pb-2 mt-1 table-top-toolbar">

                <b-row>

                </b-row>
                <b-row class="">
                    <b-col cols="12">
                        <b-form-radio v-for="item in paymentTables"
                                      class="mr-1"
                                      v-model="displayed_table"
                                      button
                                      size="md"
                                      button-variant="outline-primary"
                                      :value="item.alias"
                        >
                            <span style="white-space: nowrap;">{{$t(item.label)}}</span>
                        </b-form-radio>
                    </b-col>
                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table
                        v-if="showPayTable"
                        :ref="PREFIX+'_TABLE_pay'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span :id="'h-'+data.column" class="text-secondary d-inline-block">{{ $t(data.label).ucFirst() }}</span>
                        <b-tooltip
                                offset="50"
                                placement="top"
                                :target="'h-'+data.column"
                                triggers="hover"
                                no-fade
                                delay="1000"
                        >{{ $t(data.label) }}
                        </b-tooltip>
                    </template>

                    <template #cell(details_control)="data">

                        <feather-icon
                                :color="data.detailsShowing? 'danger' : '#7c70f9'"
                                @click="getDetails(data)"
                                size="20"
                                class="cursor-pointer"
                                :icon="data.detailsShowing? 'MinusCircleIcon' : 'PlusCircleIcon'"
                        />

                    </template>

                    <template #row-details="{item,toggleDetails}">
                        <b-card>

                            <template v-if="item.corrections.length > 0">

                                <b-table-simple small caption-top responsive no-border-collapse>
                                    <b-thead head-variant="light">
                                        <b-tr>
                                            <b-th>{{$t('label_old_value')}}</b-th>
                                            <b-th>{{$t('label_changed') + ' ' + $t('label_changed_to')}}</b-th>
                                            <b-th>{{$t('label_creating_date')}}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="cor in  item.corrections">
                                            <b-td>{{cor.old_net_payment.formatPrice()}}</b-td>
                                            <b-td>{{cor.new_net_payment.formatPrice()}}</b-td>
                                            <b-td>{{formatDate(cor.created_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </template>

                            <p v-else>{{$t('label_no_records_available')}}</p>

                        </b-card>
                    </template>
                    <template #cell(deal_number)="data">
                        {{data.item.deal_number}}
                    </template>

                    <template #cell(pay_in_cash)="data">
                        {{data.item.pay_in_cash == 1 ? $t('label_yes') : '--'}}
                    </template>
                    <template #cell(pay_for_each_participation)="data">
                        {{data.item.pay_for_each_participation == 1 ? parseInt(data.item.each_participation_days) :
                        '--'}}
                    </template>
                    <template #cell(pay_after_month)="data">
                        {{data.item.pay_after_month == 1 ? parseInt(data.item.pay_after_month_days) : '--'}}
                    </template>
                    <!--for report-->
                    <template #cell(report_file)="data">
                        <a target="_blank" :href="$domain + data.item.report_dir + data.item.report_file">
                            <feather-icon icon="FileIcon" size="16"></feather-icon>
                        </a>
                    </template>


                    <template v-if="displayed_table == 'default'" #cell(actions)="data">
                        <span v-if="data.item.type == 'each_participation_activity' || data.item.type == 'success_fee' || data.item.type == 'single_for_meeting'"></span>
                        <template v-else>
                            <button style="min-width: 100px" v-if="data.item.is_paid == 0"
                                    class="btn-outline-danger rounded-pill"
                                    @click="changePayStatus(data.item.id, 'payment')">
                                {{ $t('label_settlement') }}
                            </button>
                            <template v-if="data.item.is_paid == 1">
                                <template v-if="data.item.need_apply_correction == 0">
                                    <span class="badge text-primary d-block">{{ $t('label_settled') }}</span>
                                    <button style="min-width: 100px" class="btn-outline-primary rounded-pill"
                                            @click="correctionForId = data.item.id;$bvModal.show('pay_correction-modal')">
                                        {{ $t('label_correct') }}
                                    </button>
                                </template>
                                <button style="min-width: 100px" v-else class="btn-outline-warning rounded-pill"
                                        @click="applyCorrection(data.item.id)">
                                    {{ $t('label_correction') }}
                                </button>
                            </template>

                        </template>
                    </template>


                    <template v-if="displayed_table == 'reports_to_calculate'" #cell(report_actions)="data">

                        <button style="min-width: 100px" v-if="data.item.status == 1" class="btn-danger rounded-pill"
                                @click="changePayStatus(data.item.id , 'report')">
                            {{ $t('label_settlement') }}
                        </button>
                        <span v-if="data.item.is_paid == 1" class="badge mb-1 badge-light-success d-block">{{ $t('label_settled') }}</span>

                    </template>

                </b-table>
            </div>
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>


                </b-row>
            </div>
        </b-col>
        <b-col
                cols="12"
                md="12"
                class=" mb-1 mt-1"
        >

            <div class="pb-2 mt-1 table-top-toolbar">
                <h4 class="mb-4 header-title">
                    <b>{{$t('label_issued_invoices')}}</b>
                </h4>
                <b-row>

                    <b-col v-if="tableItems_dueLaw.length > 0"
                            cols="12"
                            md="2"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData_dueLaw.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                    </b-col>

                    <b-col v-if="$can('edit', MODULE_PREFIX+'.tab_payments')"
                            class=""
                            cols="12"
                            md="10"
                    >
                        <div class="d-flex align-items-center flex-wrap">

                            <b-button
                                      variant="success"
                                      @click="editedDueLaw = false; payForDueLaw = false, $bvModal.show('due_law-modal')"
                                      class="mr-1 mb-1"
                            >
                                <span class="text-nowrap">{{ $t('label_add_issued_invoice') }}</span>
                            </b-button>
                            <b-button v-if="!clientPayDisabled" variant="success" class="mb-1"
                                      @click="editedClientPay = false;$bvModal.show('client_payment-modal');"
                            >
                                <span class="text-nowrap">{{ $t('label_add_payment_from_client') }}</span>
                            </b-button>
                        </div>
                    </b-col>

                </b-row>

            </div>


            <b-table-simple v-if="tableItems_dueLaw.length > 0" class="multiple-table" caption-top responsive no-border-collapse>
                <b-tbody>
                    <template v-for="item in tableItems_dueLaw">
                        <b-tr class="t-caption-title">
                            <b-th colspan="8">{{$t('label_receivable') + ' #' +formatDate(item.created_at, 'DD.MM.YYYY',
                                'unix')}}
                            </b-th>
                        </b-tr>
                        <b-tr class="t-caption">
                            <b-th v-for="column in columnsDefs_dueLaw">
                                <span v-if="column.key == 'paid'">{{$t(column.label) + ' ' + $t('label_brutto')}}</span>
                                <span v-else-if="column.key == 'ide'" v-b-tooltip.hover.top="$t(column.label) + ' ' + $t('label_brutto')">{{$t(column.label) + ' ' + $t('label_brutto')}}</span>
                                <span v-else>{{$t(column.label)}}</span>
                            </b-th>
                        </b-tr>
                        <b-tr :data-pk="item.id">
                            <b-td>
                                <feather-icon
                                        :color="item.detailsShowed? 'danger' : '#7367f0'"
                                        @click="getDetails2(item)"
                                        size="20"
                                        class="cursor-pointer"
                                        :icon="item.detailsShowed? 'MinusCircleIcon' : 'PlusCircleIcon'"
                                />
                            </b-td>
                            <b-td>{{item.gross_amount? item.gross_amount .formatPrice()+ ' '+$t('label_pln_gross') : 0}}</b-td>
                            <b-td>{{formatDate(item.created_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                            <b-td>{{formatDate(item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                            <b-td>
                                <template v-if="item.documents.length > 0">
                                    <a v-for="doc in item.documents"  :title="doc.display_name" class="preview-firstly" @click="previewFile($event, AGREEMENT_PREFIX + '_' + PREFIX + 's/downloadPayDoc/', doc)"
                                       href="#">
                                        <feather-icon icon="FileIcon"></feather-icon>
                                    </a>
                                </template>
                                <span v-else>--</span>
                            </b-td>
                            <b-td>{{calcCommonNetSum(item.rows, 'gross_amount').formatPrice()+ ' ' + $t('label_pln_gross')}}</b-td>
                            <b-td>{{(parseFloat(item.gross_amount) - calcCommonNetSum(item.rows, 'gross_amount')).formatPrice()+ ' ' +
                                $t('label_pln_gross')}}
                            </b-td>
                            <b-td>
                                <b-dropdown
                                        variant="link"
                                        no-caret
                                        :right="$store.state.appConfig.isRTL"
                                >

                                    <template #button-content>
                                        <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="16"
                                                class="align-middle text-body"
                                        />
                                    </template>

                                    <template v-if="$can('edit', 'agreement.tab_payments')">
                                        <b-dropdown-item
                                                @click="payForDueLaw = item;$bvModal.show('client_payment-modal')">
                                            <feather-icon icon="AirplayIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_add_payment_from_client') }}</span>
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="editedDueLaw = item;$bvModal.show('due_law-modal'); ">
                                            <feather-icon icon="EditIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="removePayTable(item.id)">
                                            <feather-icon icon="TrashIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                                        </b-dropdown-item>

                                    </template>
                                </b-dropdown>
                            </b-td>

                        </b-tr>
                        <b-tr v-if="item.detailsShowed" class="row-details">
                            <b-td colspan="8">
                                <b-card>
                                    <b-table-simple v-if="item.rows.length > 0" small caption-top  no-border-collapse class="table-details"
                                                    style="padding-left:50px;width:100%">
                                        <b-thead head-variant="light">
                                            <b-tr>
                                                <b-td>{{$t('label_deposit_amount') + ' ' + $t('label_brutto')}}</b-td>
                                                <b-td>{{$t('label_date_of_payment_amount')}}</b-td>
                                                <b-td>{{$t('label_document')}}</b-td>
                                                <b-td>{{$t('label_action')}}</b-td>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>

                                            <b-tr v-for="subRow in item.rows">
                                                <b-td>{{subRow.gross_amount.formatPrice() + ' ' + $t('label_pln_gross')}}</b-td>
                                                <b-td>{{formatDate(subRow.payment_date_at, "DD.MM.YYYY HH:mm",
                                                    'unix')}}
                                                </b-td>
                                                <b-td>
                                                    <template
                                                            v-if="subRow.file_id && $can('view', 'agreement.tab_documents')">
                                                        {{subRow.display_name }}
                                                        <a @click="previewFile($event, AGREEMENT_PREFIX + '_' + PREFIX + 's/downloadPayDoc/', {id:subRow.file_id, hash:subRow.hash, dir:'uploads/due_law_docs/' , file_name:subRow.file_name})" href="#">
                                                            <feather-icon icon="FileIcon"/>
                                                        </a>
                                                    </template>
                                                    <span v-else>--</span>
                                                </b-td>

                                                <b-td>
                                                    <feather-icon v-if="$can('edit', 'agreement.tab_payments')"
                                                                  @click="removePayRow(subRow.id)" icon="TrashIcon"
                                                                  class="cursor-pointer" color="red"/>
                                                </b-td>
                                            </b-tr>

                                        </b-tbody>
                                    </b-table-simple>
                                    <span v-else>
                                  {{$t('label_no_records_available')}}
                              </span>
                                </b-card>
                            </b-td>
                        </b-tr>
                    </template>
                </b-tbody>

            </b-table-simple>
            <!--pagination-->
            <div class="mx-2 mb-5" v-if="tableItems_dueLaw.length > 0">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta_dueLaw.from + ' ' + $t('label_to_date') + ' ' + dataMeta_dueLaw.to + ' ' + $t('label_of') + ' ' + dataMeta_dueLaw.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData_dueLaw.currentPage"
                                :total-rows="tableTotal_dueLaw"
                                :per-page="tableData_dueLaw.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
            <b-alert :show="true"  variant="info" class="mb-4">

                <p class="d-flex"><span class="mr-auto">{{$t('label_left_to_pay')}}</span><span
                        class="pull-right">{{parseFloat(commonPayInfo.common_sum - commonPayInfo.already_paid).toFixed(2)}} zł</span>
                </p>
                <p class="d-flex"><span class="mr-auto">{{$t('label_nearest_payment_date')}}</span><span
                        class="pull-right">{{(commonPayInfo.nearest_date != '--') ? formatDate(commonPayInfo.nearest_date, 'DD.MM.YYYY' , 'unix') : commonPayInfo.nearest_date}}</span>
                </p>


            </b-alert>
        </b-col>


        <client-payment-modal
                :module-item="moduleItem"
                :is-deal="isDeal"
                :pay-for-due-law="payForDueLaw"
                :edited-item="editedClientPay"
                :receivables-options="receivables"
                @item-added="refreshDataTable_dueLaw();getCommonPaySumAndDate()"
        ></client-payment-modal>

        <due-law-modal
                :module-item="moduleItem"
                :edited-item="editedDueLaw"
                :is-deal="isDeal"
                @item-added="refreshDataTable_dueLaw();getCommonPaySumAndDate(); updateRecivables()"
                @item-edited="refreshDataTable_dueLaw();getCommonPaySumAndDate()"
        ></due-law-modal>

        <pay-correction-modal
                @item-added="refreshDataTable()"
                              :module-item="moduleItem"
                              :correction-for-id="correctionForId"
                              :is-deal="isDeal"
        ></pay-correction-modal>

    </b-row>
</template>

<script>
    import {

        BTable,
        BDropdownItem,
        BDropdown,
        BPagination,
        BFormCheckbox,
        BButton,
        BAlert,
        BCard,
        BTbody,
        BThead,
        BTh,
        BTr,
        BTableSimple,
        BTd,
        BTooltip,
        BFormRadio,
        VBTooltip

    } from 'bootstrap-vue'

    import VueUploadComponent from 'vue-upload-component'

    import vSelect from 'vue-select'
    import dueLawModal from './../modals/processDueLawModal'
    import clientPaymentModal from './../modals/processClientPaymentModal'
    import payCorrectionModal from './../modals/processPayCorrectionModal'

    import {
        // AGREEMENT_DYNAMIC_STORE_NAME,
        AGREEMENT_PREFIX,
        DEAL_PREFIX,
        PAYMENT_PREFIX as PREFIX,
        payReportTypes,
        calcCommonNetSum
    } from './../moduleHelper'

    export default {
        components: {

            BTable,
            BDropdownItem,
            BDropdown,
            BPagination,
            BFormCheckbox,
            BButton,
            BAlert,
            BCard,
            BTbody,
            BThead,
            BTh,
            BTr,
            BTableSimple,
            BTd,
            BFormRadio,
            VueUploadComponent,
            BTooltip,
            vSelect,
            dueLawModal,
            clientPaymentModal,
            payCorrectionModal
        },
        props: ['moduleItem', 'isDeal'],
        data() {
            return {
                // AGREEMENT_DYNAMIC_STORE_NAME,
                PREFIX,
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                calcCommonNetSum,
                paymentTables: [],
                tabItem: false,

                columnsDefs_payment: [

                    {label: 'label_#', key: 'details_control'},
                    {label: 'label_deal_number', key: 'deal_number'},
                    {label: 'label_payment_type', key: 'payment_trans', sortable: false},
                    {
                        label: 'label_payment_amount_netto', key: 'net_payment', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }, sortable: false
                    },
                    {
                        label: 'label_payment_amount_gross', key: 'gross_payment', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }, sortable: false
                    },
                    {
                        label: 'label_the_monthly_lump_sum_includes_a_limit_in_quantity',
                        key: 'lump_sum_months',
                        formatter(v) {
                            return v ? v.formatPrice() : '--'
                        },
                        sortable: false
                    },
                    {label: 'label_paid_in_cash', key: 'pay_in_cash', sortable: false},
                    {
                        label: 'label_before_each_implementation_on_time',
                        key: 'pay_for_each_participation',
                        sortable: false
                    },
                    {
                        label: 'label_payment_after_the_end_of_the_month_on_time',
                        key: 'pay_after_month',
                        sortable: false
                    },
                    {
                        label: 'label_invoice_issue_date', key: 'deadline_at', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix')
                        }, sortable: false
                    },
                    {label: 'label_action', key: 'actions'}
                ],
                columnsDefs_reports_to_calculate: [

                    {label: 'label_payment_creation_date', key: 'report_date', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },
                    {label: 'label_net_amount_in_agreement', key: 'net_payment',  formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }}, //if $isHourlyPayment else
                    {label: 'label_net_contract_amount', key: 'net_payment',  formatter(v) {
                            return v ? v.formatPrice() : '--'
                        },}, //if !$isHourlyPayment

                    {label: 'label_net_amount_used', key: 'in_range_sum', sortable: false, formatter(v) {
                            return v ? v.formatPrice() : '--'
                        },},// if !$isHourlyPayment

                    {label: 'label_current_report_net_amount', key: 'net_report_amount', sortable: false, formatter(v) {
                            return v ? v.formatPrice() : '--'
                        },},
                    {label: 'label_pdf_report', key: 'report_file', sortable: false},

                    {label: 'label_action', key: 'report_actions'}
                ],

                displayed_table: 'default',
                // showPayTable: true,
                columnsDefs: [],
                tableData: {},

                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData_default: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },

                isBusy_dueLaw: false,
                tableItems_dueLaw: [],
                tableTotal_dueLaw: 0,
                tableData_dueLaw: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                columnsDefs_dueLaw: [
                    {label: 'label_#', key: 'details_control', tdClass: 'details-control'},
                    {
                        label: 'label_gross_amount', key: 'gross_amount', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },
                    {
                        label: 'label_adding_date',
                        key: 'created_at',
                        sortable: false,
                        tdClass: 'rcv-name',
                        formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },
                    {label: 'label_date_of_payment', key: 'deadline_at', sortable: false},
                    {label: 'label_document', key: 'display_name', sortable: false},
                    {label: 'label_paid', key: 'paid', sortable: false},
                    {label: 'label_left_to_pay', key: 'ide', sortable: false},
                    {label: 'label_edit', key: 'actions'}
                ],
                commonPayInfo: {
                    common_sum: 0,
                    nearest_date: null,
                    already_paid: 0
                },
                receivables: [],
                editedClientPay: false,
                editedDueLaw: false,
                payForDueLaw: false,
                payCorrectionModal: false,
                correctionForId: false,

                showReportsTabs:false,
                showPayTable:true,

                hasHourlyPayment: false
            }
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            displayed_table: function (cur_table) {

                this.displayTable(cur_table);
            },
            tableData_dueLaw: {
                handler: function (newVal, Old) {
                    this.refreshDataTable_dueLaw();
                },
                deep: true
            },
          moduleItem: {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.refreshDataTable();
              }
            }
          }
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
            dataMeta_dueLaw: (context) => {

                const localItemsCount = context.$refs[context.PREFIX + '_TABLE_due'] ? context.$refs[context.PREFIX + '_TABLE_due'].localItems.length : 0;
                return {
                    from: context.tableData_dueLaw.perPage * (context.tableData_dueLaw.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: context.tableData_dueLaw.perPage * (context.tableData_dueLaw.currentPage - 1) + localItemsCount,
                    of: context.tableTotal_dueLaw,
                }
            },
            clientPayDisabled() {
                return this.tableItems_dueLaw.length < 1;
            }
        },
        methods: {
            getDetails(row) {
                let isClosed = !row.detailsShowing || row.detailsShowing === false;

                let is_sending = false;
                if (isClosed) {
                    is_sending = true;

                    this.async('get', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + '_corrections', {
                        params:{agreement_payment_id: row.item.id}
                    }, function (res) {
                        row.item.corrections = res.data;
                        if (isClosed) {
                            row.toggleDetails();
                        }
                    });
                } else {
                    row.toggleDetails();
                }

            },
            getDetails2(row) {
                let isClosed = !row.detailsShowed || row.detailsShowed === false;

                let self = this;

                if (isClosed) {
                    row.detailsShowed = true;

                } else {
                    row.detailsShowed = false;
                }
            },
            refreshDataTable: function () {
                this.isBusy = true;
                let url =  '';

                if (this.displayed_table == 'default') {
                    url = '/agreement_' + this.PREFIX + 's';
                } else if (this.displayed_table == 'reports_to_calculate') {
                    url = '/agreement_' + this.PREFIX + 's/api_adv_payments_get_data';
                } else if (this.displayed_table == 'calculated_reports') {
                    url = '/agreement_' + this.PREFIX + 's/api_adv_payments_get_data';
                }

                this.async('get', url, {
                    params: {
                        pay_info_type: this.displayed_table,
                        ['agreement_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    if (this.displayed_table == 'default') {
                        this.columnsDefs = this.columnsDefs_payment;
                    } else if (this.displayed_table == 'reports_to_calculate') {
                        this.columnsDefs = this.columnsDefs_reports_to_calculate;
                    } else if (this.displayed_table == 'calculated_reports') {
                        this.columnsDefs = this.columnsDefs_reports_to_calculate.filter((item) => item.key != 'actions');
                    }
                    this.tableItems = resp.data.items;

                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            refreshDataTable_dueLaw: function () {
                this.isBusy_dueLaw = true;
                let url = '';

                this.async('get', '/agreement_payments/api_get_due_law_rows', {
                // this.async('get', '/' + this.MODULE_PREFIX + '_payments/api_get_due_law_rows', {
                    params: {
                        ['agreement_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                        length: this.tableData_dueLaw.perPage,
                        start: (this.tableData_dueLaw.currentPage - 1) * this.tableData_dueLaw.perPage,
                        search: this.tableData_dueLaw.searchQuery,
                        sort_by: this.tableData_dueLaw.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    this.tableItems_dueLaw = resp.data.items.map((el) => {
                        el.detailsShowed = false;
                        return el;
                    });
                    this.tableTotal_dueLaw = resp.data.total;
                    this.isBusy_dueLaw = false;
                    let self = this;

                });

            },


            changePayStatus(id, type) {

                this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/api_changePayStatus/', {

                id: id, status: 'is_paid', type: type
                }, function (res) {
                    this.refreshDataTable();
                });
            },

            applyCorrection(id) {
                this.async('put', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/api_applyCorrection/'+id, {

                }, function (res) {
                    this.refreshDataTable();
                });
            },


            displayTable(table_name) {

                this.tableData = Object.assign({}, this.tableData_default);
            },

            removePayTable(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/api_removePayData/' + id, {params: {target: 'table'}}, function (resp) {
                        this.refreshDataTable_dueLaw();
                        this.getCommonPaySumAndDate();
                        this.updateRecivables();
                    });
                })
            },
            removePayRow(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/api_removePayData/' + id, {params: {target: 'row'}}, function (resp) {
                        this.refreshDataTable_dueLaw();
                        this.getCommonPaySumAndDate();
                    });

                })
            },
            getCommonPaySumAndDate() {
                this.async('get', '/agreement_' + this.PREFIX + 's/api_getCommonPaySumAndDate', {
                   params:{ [this.AGREEMENT_PREFIX + '_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id}
                }, function (resp) {
                    this.commonPayInfo.common_sum = resp.data.common_sum;
                    this.commonPayInfo.nearest_date = resp.data.nearest_date;
                    this.commonPayInfo.already_paid = resp.data.already_paid;
                })
            },
            getPaymentTabData() {

                this.async('get', '/agreement_' + this.PREFIX + 's/selects',
                    {
                        params: {
                            agreement_id: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                            payInfoType: 'default'
                        }
                    }, function (res) {

                        this.showReportsTabs = res.data.showReportsTabs;
                        this.showPayTable = res.data.showPayTable;

                        this.receivables = JSON.parse(res.data.tables);

                        if (!this.showReportsTabs) { //display only agreement main payment
                            this.paymentTables = [];
                            this.paymentTables.push(payReportTypes.findWhere('alias', 'default'));
                        } else {
                            this.paymentTables = payReportTypes;
                        }
                    });

                this.displayTable('default');
                this.refreshDataTable_dueLaw();
                this.getCommonPaySumAndDate();
            },
            updateRecivables(){

                this.async('get', '/agreement_' + this.PREFIX + 's/selects',
                    {
                        params: {
                            agreement_id:  this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                            payInfoType: 'default'
                        }
                    }, function (res) {
                        this.receivables = JSON.parse(res.data.tables);
                    });
            },

        },

        created() {
            let self = this;

            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            this.getPaymentTabData();

            if(this.moduleItem && (this.moduleItem.agreement_type_alias == 'legal_service_order_card' || this.moduleItem.agreement_type == 'legal_service_order_card')){

                for(let i =0; i<this.moduleItem.payments.length; i++) {
                    if(this.moduleItem.payments[i].type == 'hourly_payment'){

                        this.hasHourlyPayment = true;
                    }
                }
            }

            this.columnsDefs_payment = this.columnsDefs_payment.filter(function (obj) {
                if ((obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX + '.tab_' + self.PREFIX + 's')) {
                    return false;
                }
                return true;

            });
            this.columnsDefs_reports_to_calculate = this.columnsDefs_reports_to_calculate.filter(function (obj) {
                if ((obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX + '.tab_' + self.PREFIX + 's')) {
                    return false;
                } else if(obj.key == 'net_payment'){
                    if(self.hasHourlyPayment && obj.label == 'label_net_amount_in_agreement'){
                        return true;
                    } else {
                        return false;
                    }
                    if(!self.hasHourlyPayment && obj.label == 'label_net_contract_amount'){
                        return true
                    } else {
                        return false;
                    }
                } else if(obj.key == 'in_range_sum' && self.hasHourlyPayment){
                    return false
                }
                return true;

            });

        },
    }
</script>
